html {
  letter-spacing: .01rem;
  height: 100%;
  font-size: 14px;
}

html body {
  direction: ltr;
  background-color: #f8f8f8;
  height: 100%;
}

html body p {
  line-height: 1.5rem;
}

html .content {
  backface-visibility: hidden;
  min-height: calc(100% - 3.35rem);
  margin-left: 299px;
  padding: 0;
  transition: all .3s;
  position: relative;
}

html .content.app-content {
  padding: 7.75rem 2rem 0;
}

html .content.app-content.show-overlay .content-overlay {
  z-index: 10;
  opacity: 1;
}

html .content.app-content.show-overlay .content-overlay ~ .header-navbar-shadow {
  display: none;
}

html .content.app-content .content-overlay {
  opacity: 0;
  cursor: pointer;
  z-index: -1;
  background-color: #22292f80;
  width: 100%;
  height: 100%;
  transition: all .7s, z-index;
  position: fixed;
  inset: 0;
}

html .content.app-content .content-area-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
}

html .content.app-content .content-area-wrapper .content-wrapper, html .content.app-content .content-area-wrapper .content-body {
  height: 100%;
}

html .content .body-content-overlay {
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 0;
}

html .content .body-content-overlay.show {
  visibility: visible;
  opacity: 1;
  background-color: #22292f33;
  border-radius: .1785rem;
  transition: all .3s;
}

html .content .content-wrapper .content-header-title {
  color: #636363;
  border-right: 1px solid #d6dce1;
  padding-right: 1rem;
  font-weight: 500;
}

html .content .content-wrapper .content-header-right .dropdown-toggle:after {
  display: none;
}

html .content .content-wrapper .content-header-right .dropdown-toggle i, html .content .content-wrapper .content-header-right .dropdown-toggle svg {
  margin-right: 0;
}

html .content .content-wrapper .content-header-right .btn-icon {
  padding: .8rem;
}

html .content .content-wrapper .content-header-right .btn-icon i, html .content .content-wrapper .content-header-right .btn-icon svg {
  width: 1rem;
  height: 1rem;
}

html .navbar-floating.footer-static .app-content .content-area-wrapper, html .navbar-floating.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 4.45rem + 3.35rem + 1.3rem + 0rem));
  height: calc(100vh - 11.1rem);
}

@media (width <= 575.98px) {
  html .navbar-floating.footer-static .app-content .content-area-wrapper, html .navbar-floating.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 1)  - calc(.8rem * 1))  + 4.45rem + 3.35rem + 1.3rem + 0rem));
    height: calc(100vh - 10.3rem);
  }
}

html .navbar-floating.footer-hidden .app-content .content-area-wrapper, html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 0rem + 1.3rem + 0rem));
  height: calc(100vh - 9.75rem);
}

@media (width <= 575.98px) {
  html .navbar-floating.footer-hidden .app-content .content-area-wrapper, html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 4.45rem + 0rem + 1.3rem + 0rem));
    height: calc(100vh - 8.15rem);
  }
}

html .navbar-floating.footer-fixed .app-content .content-area-wrapper, html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 3.35rem + 1.3rem + 0rem));
  height: calc(100vh - 13.1rem);
}

@media (width <= 575.98px) {
  html .navbar-floating.footer-fixed .app-content .content-area-wrapper, html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 4.45rem + 3.35rem + 1.3rem + 0rem));
    height: calc(100vh - 11.5rem);
  }
}

html .navbar-hidden .app-content {
  padding: 2rem 2rem 0 !important;
}

html .navbar-hidden.footer-static .app-content .content-area-wrapper, html .navbar-hidden.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 0rem + 3.35rem + 0rem + 0rem));
  height: calc(100vh - 5.35rem);
}

@media (width <= 575.98px) {
  html .navbar-hidden.footer-static .app-content .content-area-wrapper, html .navbar-hidden.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 1)  - calc(.8rem * 1))  + 0rem + 3.35rem + 0rem + 0rem));
    height: calc(100vh - 4.55rem);
  }
}

html .navbar-hidden.footer-hidden .app-content .content-area-wrapper, html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 0rem + 0rem + 0rem + 0rem));
  height: calc(100vh - 4rem);
}

@media (width <= 575.98px) {
  html .navbar-hidden.footer-hidden .app-content .content-area-wrapper, html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 0rem + 0rem + 0rem + 0rem));
    height: calc(100vh - 2.4rem);
  }
}

html .navbar-hidden.footer-fixed .app-content .content-area-wrapper, html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 0rem + 3.35rem + 0rem + 0rem));
  height: calc(100vh - 7.35rem);
}

@media (width <= 575.98px) {
  html .navbar-hidden.footer-fixed .app-content .content-area-wrapper, html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 0rem + 3.35rem + 0rem + 0rem));
    height: calc(100vh - 5.75rem);
  }
}

html .navbar-static .main-menu {
  top: 0;
}

html .navbar-static .content {
  min-height: calc(100% - 7.8rem);
}

html .navbar-static .app-content {
  padding: 2rem 2rem 0;
}

html .navbar-static.footer-static .app-content .content-area-wrapper, html .navbar-static.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(100vh - 9.8rem);
}

@media (width <= 575.98px) {
  html .navbar-static.footer-static .app-content .content-area-wrapper, html .navbar-static.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 1)  - calc(.8rem * 1))  + 4.45rem + 3.35rem + 0rem + 0rem));
    height: calc(100vh - 9rem);
  }
}

html .navbar-static.footer-hidden .app-content .content-area-wrapper, html .navbar-static.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 0rem + 0rem + 0rem));
  height: calc(100vh - 8.45rem);
}

@media (width <= 575.98px) {
  html .navbar-static.footer-hidden .app-content .content-area-wrapper, html .navbar-static.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 4.45rem + 0rem + 0rem + 0rem));
    height: calc(100vh - 6.85rem);
  }
}

html .navbar-static.footer-fixed .app-content .content-area-wrapper, html .navbar-static.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(100vh - 11.8rem);
}

@media (width <= 575.98px) {
  html .navbar-static.footer-fixed .app-content .content-area-wrapper, html .navbar-static.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 4.45rem + 3.35rem + 0rem + 0rem));
    height: calc(100vh - 10.2rem);
  }
}

html .navbar-sticky .app-content {
  padding: 6.45rem 2rem 0;
}

html .navbar-sticky.footer-static .app-content .content-area-wrapper, html .navbar-sticky.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(100vh - 9.8rem);
}

@media (width <= 575.98px) {
  html .navbar-sticky.footer-static .app-content .content-area-wrapper, html .navbar-sticky.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 1)  - calc(.8rem * 1))  + 4.45rem + 3.35rem + 0rem + 0rem));
    height: calc(100vh - 9rem);
  }
}

html .navbar-sticky.footer-hidden .app-content .content-area-wrapper, html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 0rem + 0rem + 0rem));
  height: calc(100vh - 8.45rem);
}

@media (width <= 575.98px) {
  html .navbar-sticky.footer-hidden .app-content .content-area-wrapper, html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 4.45rem + 0rem + 0rem + 0rem));
    height: calc(100vh - 6.85rem);
  }
}

html .navbar-sticky.footer-fixed .app-content .content-area-wrapper, html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(100vh - 11.8rem);
}

@media (width <= 575.98px) {
  html .navbar-sticky.footer-fixed .app-content .content-area-wrapper, html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem * 2)  - calc(.8rem * 2))  + 4.45rem + 3.35rem + 0rem + 0rem));
    height: calc(100vh - 10.2rem);
  }
}

html .navbar-static .nav.header-navbar .navbar-container, html .navbar-sticky .nav.header-navbar .navbar-container {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

html .navbar-static .nav.header-navbar .navbar-container .search-input .search-list.show, html .navbar-sticky .nav.header-navbar .navbar-container .search-input .search-list.show {
  width: 98%;
  left: 1%;
}

html .footer-fixed .content.app-content {
  padding-bottom: 3.35rem !important;
}

html [data-col="1-column"] .header-navbar {
  width: 100%;
  left: 0 !important;
}

html [data-col="1-column"] .header-navbar.floating-nav {
  width: calc(100% - 4rem) !important;
}

html [data-col="1-column"] .content, html [data-col="1-column"] .footer {
  margin-left: 0 !important;
}

html [data-col="1-column"].horizontal-layout .header-navbar {
  width: 100% !important;
}

html [data-col="1-column"].horizontal-layout .app-content {
  padding: 7.75rem 2rem 0 !important;
}

html [data-col="1-column"].horizontal-layout.footer-fixed .app-content {
  padding: 7.75rem 2rem 3.35rem !important;
}

html .blank-page .content {
  margin-left: 0;
}

html .blank-page .content.app-content {
  overflow: overlay;
  transition: none;
  overflow-x: hidden;
  padding: 0 !important;
}

html .blank-page .content.app-content .header-navbar-shadow {
  display: none;
}

html .blank-page .content-wrapper {
  padding: 0 !important;
}

html .blank-page .content-wrapper .flexbox-container {
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

html .pace .pace-progress {
  background: #7367f0;
}

.app-content.center-layout {
  overflow: hidden;
}

@media (width >= 992px) {
  body .content-right {
    float: right;
    width: calc(100% - 299px);
  }

  body .content-left {
    float: left;
    width: calc(100% - 299px);
  }

  body .content-detached {
    width: 100%;
  }

  body .content-detached.content-right {
    float: right;
    margin-left: -299px;
  }

  body .content-detached.content-right .content-body {
    margin-left: calc(299px + 2rem);
  }

  body .content-detached.content-left {
    float: left;
    margin-right: -299px;
  }

  body .content-detached.content-left .content-body {
    margin-right: calc(299px + 2rem);
  }

  .sidebar-right.sidebar-sticky {
    margin-top: 5.7rem;
    margin-left: -299px;
    float: right !important;
    width: 299px !important;
  }
}

@media (width <= 767.98px) {
  html body .app-content {
    padding: 6.95rem 1.2rem 0 !important;
  }

  html body.navbar-static .app-content {
    padding: 1.2rem 1.2rem 0 !important;
  }

  html body.navbar-sticky .app-content {
    padding: 5.65rem 1.2rem 0 !important;
  }

  html body.navbar-hidden .app-content {
    padding: 1.2rem 1.2rem 0 !important;
  }

  .navbar-static .app-content .header-navbar .navbar-container, .navbar-sticky .app-content .header-navbar .navbar-container {
    padding-left: 1rem;
    padding-right: .5rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html.full-screen {
    width: 100%;
  }

  html.full-screen .content.app-content {
    height: 100%;
    overflow: hidden scroll;
  }
}

.sidebar {
  width: 100%;
  height: 100%;
  position: relative;
}

@media (width >= 992px) {
  .sidebar {
    vertical-align: top;
  }
}

.sidebar-fixed {
  height: 100%;
  position: fixed;
  overflow: scroll;
}

.sidenav-overlay {
  z-index: 997;
  visibility: hidden;
  opacity: 0;
  background-color: #22292f80;
  height: 120vh;
  transition: all .5s;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.sidenav-overlay.show {
  opacity: 1;
  visibility: visible;
  transition: all .5s;
}

.drag-target {
  z-index: 1036;
  width: 40px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -10px;
}

.sidebar-detached .sidebar {
  width: 299px;
}

@media (width >= 992px) {
  .sidebar-left {
    float: left;
  }

  .sidebar-right {
    float: right;
  }
}

@media (width <= 991.98px) {
  .sidebar-detached .sidebar {
    width: 100%;
  }
}

footer.footer {
  min-height: 3.35rem;
  padding: .8rem 2rem;
  transition: all .3s;
}

footer.footer span i, footer.footer span svg {
  color: #ea5455;
  width: 1.51rem;
  height: 1.51rem;
  margin-left: .5rem;
  font-size: 1.51rem;
}

footer.footer .scroll-top {
  padding: .81rem .83rem;
}

footer.footer-shadow {
  box-shadow: 0 4px 24px #22292f1a;
}

footer.footer-border {
  border-top: 1px solid #ebe9f1;
}

.footer-fixed footer {
  z-index: 1030;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed !important;
}

.footer-fixed footer.footer-light {
  background: #fff;
  box-shadow: 0 4px 24px #22292f1a;
}

.footer-fixed footer.footer-dark {
  color: #fff;
  background: #b8c2cc;
}

.footer-hidden .app-content {
  padding-bottom: .1px !important;
}

.scroll-top {
  z-index: 99;
  display: none;
  position: fixed;
  bottom: 5%;
  right: 30px;
}

.main-menu {
  z-index: 1031;
  height: 100%;
  display: table-cell;
  position: absolute;
  overflow: hidden;
}

.main-menu.menu-light {
  color: #6e6b7b;
  background: #fff;
}

.main-menu.menu-light .navigation {
  background: #fff;
}

.main-menu.menu-light .navigation .navigation-header {
  color: #a6a4b0;
  letter-spacing: .01rem;
  margin: 2.286rem 0 .8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
}

.main-menu.menu-light .navigation .navigation-header span {
  font-weight: 500;
}

.main-menu.menu-light .navigation li.has-sub > a {
  transition: all .5s, background, color;
}

.main-menu.menu-light .navigation li a {
  color: #625f6e;
  padding: 10px 15px;
  line-height: 1.45;
}

.main-menu.menu-light .navigation li a span.menu-sub-title {
  color: #bdbbc4;
}

.main-menu.menu-light .navigation li a > * {
  transition: transform .25s;
}

.main-menu.menu-light .navigation li.nav-item a:hover > *, .main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover > * {
  transition: transform .25s;
  transform: translateX(5px);
}

.main-menu.menu-light .navigation > li > a {
  margin: 0 15px;
}

.main-menu.menu-light .navigation > li > a .label {
  margin-top: 4px;
  margin-right: 5px;
}

.main-menu.menu-light .navigation > li > a i, .main-menu.menu-light .navigation > li > a svg {
  position: relative;
  top: 1px;
}

.main-menu.menu-light .navigation > li > ul li:first-of-type {
  margin-top: 7px !important;
}

.main-menu.menu-light .navigation > li > ul li:not(.has-sub), .main-menu.menu-light .navigation > li > ul li.has-sub > a, .main-menu.menu-light .navigation > li > ul li.has-sub > ul > li {
  margin: 0 15px;
}

.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  color: #565360;
  background: #f5f5f5;
  border-radius: 6px;
}

.main-menu.menu-light .navigation > li:not(.open) > ul {
  display: none;
}

.main-menu.menu-light .navigation > li:not(.open) > ul .active {
  box-shadow: none;
  background: none;
}

.main-menu.menu-light .navigation > li.active {
  z-index: 1;
}

.main-menu.menu-light .navigation > li.active > a {
  color: #fff;
  background: linear-gradient(118deg, #7367f0, #7367f0b3);
  border-radius: 4px;
  font-weight: 400;
  box-shadow: 0 0 10px 1px #7367f0b3;
}

.main-menu.menu-light .navigation > li .active {
  background: #f0f0f0;
}

.main-menu.menu-light .navigation > li .active > a {
  color: #7367f0;
  margin-bottom: 0;
}

.main-menu.menu-light .navigation > li .active .hover > a, .main-menu.menu-light .navigation > li .active :hover > a {
  background: #f5f5f5;
}

.main-menu.menu-light .navigation > li ul {
  margin: 0;
  padding: 0;
}

.main-menu.menu-light .navigation > li ul li {
  color: #6e6b7b;
  background: none;
}

.main-menu.menu-light .navigation > li ul li > a {
  padding: 10px 15px 10px 20px;
}

.main-menu.menu-light .navigation > li ul li ul a {
  padding: 10px 15px 10px 53px;
}

.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul {
  display: none;
}

.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul .active {
  box-shadow: none;
  background: none;
}

.main-menu.menu-light .navigation > li ul .open > a, .main-menu.menu-light .navigation > li ul .sidebar-group-active > a {
  color: #6e6b7b;
  background: #f5f5f5;
  border-radius: 6px;
}

.main-menu.menu-light .navigation > li ul .open > ul, .main-menu.menu-light .navigation > li ul .sidebar-group-active > ul, .main-menu.menu-light .navigation > li ul .open > ul .open > ul, .main-menu.menu-light .navigation > li ul .sidebar-group-active > ul .open > ul {
  display: block;
}

.main-menu.menu-light .navigation > li ul .hover > a, .main-menu.menu-light .navigation > li ul:hover > a {
  color: #787486;
}

.main-menu.menu-light .navigation > li ul .active {
  z-index: 1;
  background: linear-gradient(118deg, #7367f0, #7367f0b3);
  border-radius: 4px;
  box-shadow: 0 0 10px 1px #7367f0b3;
}

.main-menu.menu-light .navigation > li ul .active > a {
  color: #fff;
}

.main-menu.menu-dark {
  color: #dcdcdc;
  background: #10163a;
}

.main-menu.menu-dark .navigation {
  background: #10163a;
}

.main-menu.menu-dark .navigation .navigation-header {
  color: #fff;
  letter-spacing: .01rem;
  margin: 2.286rem 0 .8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
}

.main-menu.menu-dark .navigation .navigation-header span {
  font-weight: 500;
}

.main-menu.menu-dark .navigation li.has-sub > a {
  transition: all .5s, background, color;
}

.main-menu.menu-dark .navigation li a {
  color: #d0d0d0;
  padding: 10px 15px;
  line-height: 1.45;
}

.main-menu.menu-dark .navigation li a span.menu-sub-title {
  color: #fff;
}

.main-menu.menu-dark .navigation li a > * {
  transition: transform .25s;
}

.main-menu.menu-dark .navigation li.nav-item a:hover > *, .main-menu.menu-dark .navigation li.nav-item ul.menu-content li a:hover > * {
  transition: transform .25s;
  transform: translateX(5px);
}

.main-menu.menu-dark .navigation > li > a {
  margin: 0 15px;
}

.main-menu.menu-dark .navigation > li > a .label {
  margin-top: 4px;
  margin-right: 5px;
}

.main-menu.menu-dark .navigation > li > a i, .main-menu.menu-dark .navigation > li > a svg {
  position: relative;
  top: 1px;
}

.main-menu.menu-dark .navigation > li > ul li:first-of-type {
  margin-top: 7px !important;
}

.main-menu.menu-dark .navigation > li > ul li:not(.has-sub), .main-menu.menu-dark .navigation > li > ul li.has-sub > a, .main-menu.menu-dark .navigation > li > ul li.has-sub > ul > li {
  margin: 0 15px;
}

.main-menu.menu-dark .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-dark .navigation > li.sidebar-group-active > a {
  color: #c3c3c3;
  background: #0c102a;
  border-radius: 6px;
}

.main-menu.menu-dark .navigation > li:not(.open) > ul {
  display: none;
}

.main-menu.menu-dark .navigation > li:not(.open) > ul .active {
  box-shadow: none;
  background: none;
}

.main-menu.menu-dark .navigation > li.active {
  z-index: 1;
}

.main-menu.menu-dark .navigation > li.active > a {
  color: #fff;
  background: linear-gradient(118deg, #7367f0, #7367f0b3);
  border-radius: 4px;
  font-weight: 400;
  box-shadow: 0 0 10px 1px #7367f0b3;
}

.main-menu.menu-dark .navigation > li .active {
  background: #090d22;
}

.main-menu.menu-dark .navigation > li .active > a {
  color: #7367f0;
  margin-bottom: 0;
}

.main-menu.menu-dark .navigation > li .active .hover > a, .main-menu.menu-dark .navigation > li .active :hover > a {
  background: #0c102a;
}

.main-menu.menu-dark .navigation > li ul {
  margin: 0;
  padding: 0;
}

.main-menu.menu-dark .navigation > li ul li {
  color: #dcdcdc;
  background: none;
}

.main-menu.menu-dark .navigation > li ul li > a {
  padding: 10px 15px 10px 20px;
}

.main-menu.menu-dark .navigation > li ul li ul a {
  padding: 10px 15px 10px 53px;
}

.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul {
  display: none;
}

.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul .active {
  box-shadow: none;
  background: none;
}

.main-menu.menu-dark .navigation > li ul .open > a, .main-menu.menu-dark .navigation > li ul .sidebar-group-active > a {
  color: #dcdcdc;
  background: #0c102a;
  border-radius: 6px;
}

.main-menu.menu-dark .navigation > li ul .open > ul, .main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul, .main-menu.menu-dark .navigation > li ul .open > ul .open > ul, .main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul .open > ul {
  display: block;
}

.main-menu.menu-dark .navigation > li ul .hover > a, .main-menu.menu-dark .navigation > li ul:hover > a {
  color: #e6e6e6;
}

.main-menu.menu-dark .navigation > li ul .active {
  z-index: 1;
  background: linear-gradient(118deg, #7367f0, #7367f0b3);
  border-radius: 4px;
  box-shadow: 0 0 10px 1px #7367f0b3;
}

.main-menu.menu-dark .navigation > li ul .active > a {
  color: #fff;
}

.main-menu.menu-fixed {
  position: fixed;
}

.main-menu.menu-static {
  height: 100%;
  padding-bottom: calc(100% - 20rem);
}

.main-menu.menu-static .main-menu-content {
  height: unset !important;
}

.main-menu.menu-shadow {
  box-shadow: 0 0 15px #22292f0d;
}

.main-menu.menu-border {
  border-right: 1px solid #ebe9f1;
}

.main-menu .shadow-bottom {
  z-index: 2;
  pointer-events: none;
  filter: blur(5px);
  background: linear-gradient(#fff 41%, #ffffff1c 95%, #fff0);
  width: 100%;
  height: 50px;
  margin-top: -.7rem;
  display: none;
  position: absolute;
}

.bordered-layout .main-menu .shadow-bottom {
  background: linear-gradient(#f8f8f8 41%, #ffffff1c 95%, #fff0);
}

.main-menu.menu-native-scroll .main-menu-content {
  overflow-y: scroll !important;
}

.main-menu .navbar-header {
  width: 299px;
  height: 4.45rem;
  padding: .35rem 1rem .3rem 1.64rem;
  transition: all .3s, background;
  position: relative;
}

.main-menu .navbar-header .navbar-brand {
  font-size: inherit;
  align-items: center;
  margin-top: 1.35rem;
  display: flex;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 36px;
}

.main-menu .navbar-header .navbar-brand .brand-text {
  color: #7367f0;
  letter-spacing: .01rem;
  margin-bottom: 0;
  padding-left: 1rem;
  font-size: 1.45rem;
  font-weight: 600;
  animation: .3s cubic-bezier(.25, .8, .25, 1) forwards fadein;
}

.main-menu .navbar-header .modern-nav-toggle {
  animation: .3s cubic-bezier(.25, .8, .25, 1) forwards fadein;
}

.main-menu .main-menu-content {
  position: relative;
  height: calc(100% - 4.45rem) !important;
}

.main-menu .main-menu-content .ps__rail-y {
  z-index: 3;
}

.main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-menu ul.navigation-main {
  padding-bottom: .75rem;
  overflow-x: hidden;
}

.main-menu ul.navigation-main > li:first-child {
  margin-top: .5rem;
}

.main-menu a {
  outline: none;
}

.main-menu a:hover, .main-menu a:focus {
  text-decoration: none;
}

.navigation {
  padding-bottom: 20px;
  font-family: Montserrat, Helvetica, Arial, serif;
  font-size: 1.1rem;
  font-weight: 400;
  overflow-y: hidden;
}

.navigation .navigation-header {
  text-transform: uppercase;
  padding: 12px 22px;
  font-family: Montserrat, Helvetica, Arial, serif;
  font-size: .9rem;
  font-weight: 500;
  line-height: 1.2;
}

.navigation li {
  white-space: nowrap;
  position: relative;
}

.navigation li a {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.navigation li a i, .navigation li a svg {
  width: 11px;
  height: 11px;
  margin-right: 1.38rem;
  font-size: 11px;
}

.navigation li.menu-item-animating {
  transition: height .25s ease-in-out;
}

.navigation li.disabled a {
  cursor: not-allowed;
  opacity: .65;
  pointer-events: none;
}

@media (width <= 767.98px) {
  .menu-hide .main-menu, .menu-open .main-menu {
    transition: transform .25s, top .35s, height .35s;
  }

  .main-menu {
    backface-visibility: hidden;
    perspective: 1000px;
    transform: translate3d(-240px, 0, 0);
  }

  .menu-open .main-menu {
    transform: translate3d(0, 0, 0);
  }
}

@media (width >= 768px) {
  .drag-target {
    z-index: 0;
  }
}

.avatar {
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  background-color: #c3c3c3;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 600;
  display: inline-flex;
  position: relative;
}

.avatar .avatar-content {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: .857rem;
  display: flex;
}

.avatar .avatar-content .avatar-icon {
  width: 1rem;
  height: 1rem;
}

.avatar [class*="avatar-status-"] {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.avatar [class*="avatar-status-"].avatar-status-lg, .avatar [class*="avatar-status-"].avatar-status-xl {
  border-width: 2px;
  width: 17px;
  height: 17px;
}

.avatar .avatar-status-online {
  background-color: #28c76f;
}

.avatar .avatar-status-busy {
  background-color: #ea5455;
}

.avatar .avatar-status-away {
  background-color: #ff9f43;
}

.avatar .avatar-status-offline {
  background-color: #82868b;
}

.avatar.avatar-tag {
  border-radius: .357rem;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  display: flex;
}

.avatar img {
  border-radius: 50%;
}

.avatar.avatar-xl {
  font-size: 1.5rem;
}

.avatar.avatar-xl img {
  width: 70px;
  height: 70px;
}

.avatar.avatar-xl .avatar-content {
  width: 70px;
  height: 70px;
  font-size: 2.57rem;
}

.avatar.avatar-xl .avatar-content .avatar-icon, .avatar.avatar-xl .avatar-content i, .avatar.avatar-xl .avatar-content svg {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.avatar.avatar-xl [class*="avatar-status-"] {
  bottom: 0;
  right: 3px;
}

.avatar.avatar-lg {
  font-size: 1.2rem;
}

.avatar.avatar-lg img {
  width: 50px;
  height: 50px;
}

.avatar.avatar-lg .avatar-content {
  width: 50px;
  height: 50px;
  font-size: 1.714rem;
}

.avatar.avatar-lg .avatar-content .avatar-icon, .avatar.avatar-lg .avatar-content i, .avatar.avatar-lg .avatar-content svg {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

.avatar.avatar-lg [class*="avatar-status-"] {
  bottom: 1px;
  right: 3px;
}

.avatar.avatar-sm .avatar-content {
  width: 24px;
  height: 24px;
}

.avatar.avatar-sm .avatar-content .avatar-icon, .avatar.avatar-sm .avatar-content i, .avatar.avatar-sm .avatar-content svg {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.avatar.avatar-sm img {
  width: 24px;
  height: 24px;
}

.avatar.avatar-sm [class*="avatar-status-"] {
  bottom: -2px;
  right: -3px;
}

.avatar-group {
  justify-content: flex-start;
  display: flex;
}

.avatar-group .avatar {
  transition: all .25s;
}

.avatar-group .avatar img, .avatar-group .avatar .avatar-content {
  box-shadow: 0 0 0 2px #fff, inset 0 0 0 1px #22292f12;
}

.avatar-group .avatar .avatar-content {
  background-color: #9b9ea2 !important;
}

.avatar-group .avatar:hover {
  transition: all .25s;
}

.avatar-group .avatar.pull-up:hover {
  transform: translateY(-4px)scale(1.07);
}

.avatar-group .avatar:not(:first-child) {
  margin-left: -.55rem;
}

.avatar-group .avatar-sm:not(:first-child) {
  margin-left: -.4rem;
}

.avatar-group .avatar-lg:not(:first-child) {
  margin-left: -1rem;
}

.avatar-group .avatar-xl:not(:first-child) {
  margin-left: -1.5rem;
}

.navbar-container a.nav-link-search {
  float: left;
}

.navbar-container .search-input {
  float: left;
  width: 0;
  display: none;
}

.navbar-container .search-input input {
  background: none;
  border: none;
  width: 0;
  padding: 1.6rem 3.6rem;
  line-height: 16px;
  transition: all .2s ease-out;
}

.navbar-container .search-input.open {
  z-index: 1000;
  background: #fff;
  border-radius: .5rem;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar-container .search-input.open .search-input-close, .navbar-container .search-input.open .search-input-icon {
  display: block;
}

.navbar-container .search-input.open input {
  box-shadow: none;
  background: none;
  outline: none;
  width: 100%;
  height: auto;
  transition: all .3s ease-out;
}

.navbar-container .search-input .search-list {
  background: #fff;
  border-radius: .357rem;
  width: 100%;
  height: auto;
  max-height: 450px;
  margin-top: .5rem;
  padding-left: 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

@media only screen and (device-width >= 320px) and (device-width <= 875px) and (orientation: landscape) {
  .navbar-container .search-input .search-list {
    max-height: 250px;
  }
}

.navbar-container .search-input .search-list.search-list-bookmark {
  height: auto !important;
}

.navbar-container .search-input .search-list.search-list-bookmark i, .navbar-container .search-input .search-list.search-list-bookmark svg {
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
}

.navbar-container .search-input .search-list.show {
  min-height: 3rem;
  display: block;
}

.navbar-container .search-input .search-list li a {
  color: #6e6b7b;
  padding: .9rem 1rem;
}

.navbar-container .search-input .search-list li a i, .navbar-container .search-input .search-list li a svg[class*="feather feather-"] {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.navbar-container .search-input .search-list li:first-child {
  border-top-left-radius: .357rem;
  border-top-right-radius: .357rem;
}

.navbar-container .search-input .search-list li:last-child {
  border-bottom-right-radius: .357rem;
  border-bottom-left-radius: .357rem;
}

.navbar-container .search-input .search-list li.auto-suggestion {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.navbar-container .search-input .search-list li.auto-suggestion:hover, .navbar-container .search-input .search-list li.auto-suggestion.current_item {
  background-color: #f8f8f8;
}

.navbar-container .search-input .search-input-icon {
  z-index: 2;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 32%;
  left: 1.5rem;
}

.navbar-container .search-input .search-input-icon i, .navbar-container .search-input .search-input-icon svg {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.navbar-container .search-input .search-input-close {
  z-index: 1;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 32%;
  right: 2rem;
}

.navbar-container .search-input .search-input-close i, .navbar-container .search-input .search-input-close svg {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.navbar-container .bookmark-input {
  background: #fff;
  border-radius: .357rem;
  width: 25%;
  display: none;
  position: absolute;
  top: 102%;
  box-shadow: 0 15px 30px #22292f1c, 0 5px 15px #22292f14;
}

.navbar-container .bookmark-input.show {
  display: block;
}

.navbar-container .bookmark-input .bookmark-input-icon {
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 24px;
}

.navbar-container .bookmark-input input {
  background: #fff;
  border: 1px solid #22292f33;
  width: calc(100% - 24px);
  margin: 12px;
  padding: .7rem .7rem .7rem 2.5rem;
}

.navbar-container .bookmark-input input:focus {
  border: 1px solid #7367f0;
}

.navbar-container .bookmark-input .search-list {
  position: relative;
}

.navbar-container .bookmark-icon {
  cursor: pointer;
}

.navbar-container .dropdown.bookmark-dropdown {
  padding: 1.4rem .5rem 1.35rem;
}

.navbar-container .dropdown.bookmark-dropdown .dropdown-toggle:after {
  display: none;
}

.navbar-container .search-data-title {
  line-height: 1.25rem;
}

.navbar-dark .search-input .input {
  color: #f5f7fa;
  border-radius: .5rem;
}

.navbar-dark .search-input.open .input {
  color: #f5f7fa;
  border: 1px solid #f5f7fa;
}

.navbar-static .navbar-container .search-input.open {
  border-radius: 0;
}

.btn-social, .btn-social-icon {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 4.95rem;
  position: relative;
  overflow: hidden;
}

.btn-social > :first-child, .btn-social-icon > :first-child {
  text-align: center;
  border-right: 1px solid #22292f33;
  width: 2.95rem;
  font-size: 1rem;
  line-height: 2.7rem;
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
}

.btn-social-icon {
  width: 2.95rem;
  height: 2.95rem;
  padding: 0;
}

.btn-social-icon > :first-child {
  text-align: center;
  border: none;
  width: 100% !important;
}

.btn-adn {
  color: #fff;
  background-color: #d87a68;
  border: none;
  color: #fff !important;
}

.btn-adn:hover {
  color: #fff;
  background-color: #b86858;
  border-color: #0003;
}

.btn-check:focus + .btn-adn, .btn-adn:focus {
  color: #fff;
  background-color: #b86858;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-adn, .btn-check:active + .btn-adn, .btn-adn:active, .btn-adn.active, .show > .btn-adn.dropdown-toggle {
  color: #fff;
  background-color: #ad6253;
  border-color: #00000040;
}

.btn-check:checked + .btn-adn:focus, .btn-check:active + .btn-adn:focus, .btn-adn:active:focus, .btn-adn.active:focus, .show > .btn-adn.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-adn:disabled, .btn-adn.disabled {
  color: #fff;
  background-color: #d87a68;
  border-color: #0000;
}

.btn-adn:focus {
  box-shadow: none !important;
}

.btn-bitbucket {
  color: #fff;
  background-color: #205081;
  border: none;
  color: #fff !important;
}

.btn-bitbucket:hover {
  color: #fff;
  background-color: #1b446e;
  border-color: #0003;
}

.btn-check:focus + .btn-bitbucket, .btn-bitbucket:focus {
  color: #fff;
  background-color: #1b446e;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-bitbucket, .btn-check:active + .btn-bitbucket, .btn-bitbucket:active, .btn-bitbucket.active, .show > .btn-bitbucket.dropdown-toggle {
  color: #fff;
  background-color: #1a4067;
  border-color: #00000040;
}

.btn-check:checked + .btn-bitbucket:focus, .btn-check:active + .btn-bitbucket:focus, .btn-bitbucket:active:focus, .btn-bitbucket.active:focus, .show > .btn-bitbucket.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-bitbucket:disabled, .btn-bitbucket.disabled {
  color: #fff;
  background-color: #205081;
  border-color: #0000;
}

.btn-bitbucket:focus {
  box-shadow: none !important;
}

.btn-dropbox {
  color: #fff;
  background-color: #1087dd;
  border: none;
  color: #fff !important;
}

.btn-dropbox:hover {
  color: #fff;
  background-color: #0e73bc;
  border-color: #0003;
}

.btn-check:focus + .btn-dropbox, .btn-dropbox:focus {
  color: #fff;
  background-color: #0e73bc;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-dropbox, .btn-check:active + .btn-dropbox, .btn-dropbox:active, .btn-dropbox.active, .show > .btn-dropbox.dropdown-toggle {
  color: #fff;
  background-color: #0d6cb1;
  border-color: #00000040;
}

.btn-check:checked + .btn-dropbox:focus, .btn-check:active + .btn-dropbox:focus, .btn-dropbox:active:focus, .btn-dropbox.active:focus, .show > .btn-dropbox.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-dropbox:disabled, .btn-dropbox.disabled {
  color: #fff;
  background-color: #1087dd;
  border-color: #0000;
}

.btn-dropbox:focus {
  box-shadow: none !important;
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border: none;
  color: #fff !important;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #324c81;
  border-color: #0003;
}

.btn-check:focus + .btn-facebook, .btn-facebook:focus {
  color: #fff;
  background-color: #324c81;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-facebook, .btn-check:active + .btn-facebook, .btn-facebook:active, .btn-facebook.active, .show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2f477a;
  border-color: #00000040;
}

.btn-check:checked + .btn-facebook:focus, .btn-check:active + .btn-facebook:focus, .btn-facebook:active:focus, .btn-facebook.active:focus, .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-facebook:disabled, .btn-facebook.disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #0000;
}

.btn-facebook:focus {
  box-shadow: none !important;
}

.btn-flickr {
  color: #fff;
  background-color: #ff0084;
  border: none;
  color: #fff !important;
}

.btn-flickr:hover {
  color: #fff;
  background-color: #d90070;
  border-color: #0003;
}

.btn-check:focus + .btn-flickr, .btn-flickr:focus {
  color: #fff;
  background-color: #d90070;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-flickr, .btn-check:active + .btn-flickr, .btn-flickr:active, .btn-flickr.active, .show > .btn-flickr.dropdown-toggle {
  color: #fff;
  background-color: #cc006a;
  border-color: #00000040;
}

.btn-check:checked + .btn-flickr:focus, .btn-check:active + .btn-flickr:focus, .btn-flickr:active:focus, .btn-flickr.active:focus, .show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-flickr:disabled, .btn-flickr.disabled {
  color: #fff;
  background-color: #ff0084;
  border-color: #0000;
}

.btn-flickr:focus {
  box-shadow: none !important;
}

.btn-foursquare {
  color: #fff;
  background-color: #f94877;
  border: none;
  color: #fff !important;
}

.btn-foursquare:hover {
  color: #fff;
  background-color: #d43d65;
  border-color: #0003;
}

.btn-check:focus + .btn-foursquare, .btn-foursquare:focus {
  color: #fff;
  background-color: #d43d65;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-foursquare, .btn-check:active + .btn-foursquare, .btn-foursquare:active, .btn-foursquare.active, .show > .btn-foursquare.dropdown-toggle {
  color: #fff;
  background-color: #c73a5f;
  border-color: #00000040;
}

.btn-check:checked + .btn-foursquare:focus, .btn-check:active + .btn-foursquare:focus, .btn-foursquare:active:focus, .btn-foursquare.active:focus, .show > .btn-foursquare.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-foursquare:disabled, .btn-foursquare.disabled {
  color: #fff;
  background-color: #f94877;
  border-color: #0000;
}

.btn-foursquare:focus {
  box-shadow: none !important;
}

.btn-github {
  color: #fff;
  background-color: #444;
  border: none;
  color: #fff !important;
}

.btn-github:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #0003;
}

.btn-check:focus + .btn-github, .btn-github:focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-github, .btn-check:active + .btn-github, .btn-github:active, .btn-github.active, .show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #363636;
  border-color: #00000040;
}

.btn-check:checked + .btn-github:focus, .btn-check:active + .btn-github:focus, .btn-github:active:focus, .btn-github.active:focus, .show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-github:disabled, .btn-github.disabled {
  color: #fff;
  background-color: #444;
  border-color: #0000;
}

.btn-github:focus {
  box-shadow: none !important;
}

.btn-google {
  color: #fff;
  background-color: #dd4b39;
  border: none;
  color: #fff !important;
}

.btn-google:hover {
  color: #fff;
  background-color: #bc4030;
  border-color: #0003;
}

.btn-check:focus + .btn-google, .btn-google:focus {
  color: #fff;
  background-color: #bc4030;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-google, .btn-check:active + .btn-google, .btn-google:active, .btn-google.active, .show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #b13c2e;
  border-color: #00000040;
}

.btn-check:checked + .btn-google:focus, .btn-check:active + .btn-google:focus, .btn-google:active:focus, .btn-google.active:focus, .show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-google:disabled, .btn-google.disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: #0000;
}

.btn-google:focus {
  box-shadow: none !important;
}

.btn-instagram {
  color: #fff;
  background-color: #3f729b;
  border: none;
  color: #fff !important;
}

.btn-instagram:hover {
  color: #fff;
  background-color: #366184;
  border-color: #0003;
}

.btn-check:focus + .btn-instagram, .btn-instagram:focus {
  color: #fff;
  background-color: #366184;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-instagram, .btn-check:active + .btn-instagram, .btn-instagram:active, .btn-instagram.active, .show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #325b7c;
  border-color: #00000040;
}

.btn-check:checked + .btn-instagram:focus, .btn-check:active + .btn-instagram:focus, .btn-instagram:active:focus, .btn-instagram.active:focus, .show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-instagram:disabled, .btn-instagram.disabled {
  color: #fff;
  background-color: #3f729b;
  border-color: #0000;
}

.btn-instagram:focus {
  box-shadow: none !important;
}

.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border: none;
  color: #fff !important;
}

.btn-linkedin:hover {
  color: #fff;
  background-color: #00699b;
  border-color: #0003;
}

.btn-check:focus + .btn-linkedin, .btn-linkedin:focus {
  color: #fff;
  background-color: #00699b;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-linkedin, .btn-check:active + .btn-linkedin, .btn-linkedin:active, .btn-linkedin.active, .show > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #006292;
  border-color: #00000040;
}

.btn-check:checked + .btn-linkedin:focus, .btn-check:active + .btn-linkedin:focus, .btn-linkedin:active:focus, .btn-linkedin.active:focus, .show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-linkedin:disabled, .btn-linkedin.disabled {
  color: #fff;
  background-color: #007bb6;
  border-color: #0000;
}

.btn-linkedin:focus {
  box-shadow: none !important;
}

.btn-microsoft {
  color: #fff;
  background-color: #2672ec;
  border: none;
  color: #fff !important;
}

.btn-microsoft:hover {
  color: #fff;
  background-color: #2061c9;
  border-color: #0003;
}

.btn-check:focus + .btn-microsoft, .btn-microsoft:focus {
  color: #fff;
  background-color: #2061c9;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-microsoft, .btn-check:active + .btn-microsoft, .btn-microsoft:active, .btn-microsoft.active, .show > .btn-microsoft.dropdown-toggle {
  color: #fff;
  background-color: #1e5bbd;
  border-color: #00000040;
}

.btn-check:checked + .btn-microsoft:focus, .btn-check:active + .btn-microsoft:focus, .btn-microsoft:active:focus, .btn-microsoft.active:focus, .show > .btn-microsoft.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-microsoft:disabled, .btn-microsoft.disabled {
  color: #fff;
  background-color: #2672ec;
  border-color: #0000;
}

.btn-microsoft:focus {
  box-shadow: none !important;
}

.btn-odnoklassniki {
  color: #22292f;
  background-color: #f4731c;
  border: none;
  color: #fff !important;
}

.btn-odnoklassniki:hover {
  color: #22292f;
  background-color: #f6883e;
  border-color: #ffffff1a;
}

.btn-check:focus + .btn-odnoklassniki, .btn-odnoklassniki:focus {
  color: #22292f;
  background-color: #f6883e;
  border-color: #ffffff1a;
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-check:checked + .btn-odnoklassniki, .btn-check:active + .btn-odnoklassniki, .btn-odnoklassniki:active, .btn-odnoklassniki.active, .show > .btn-odnoklassniki.dropdown-toggle {
  color: #22292f;
  background-color: #f68f49;
  border-color: #ffffff1a;
}

.btn-check:checked + .btn-odnoklassniki:focus, .btn-check:active + .btn-odnoklassniki:focus, .btn-odnoklassniki:active:focus, .btn-odnoklassniki.active:focus, .show > .btn-odnoklassniki.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-odnoklassniki:disabled, .btn-odnoklassniki.disabled {
  color: #22292f;
  background-color: #f4731c;
  border-color: #0000;
}

.btn-odnoklassniki:focus {
  box-shadow: none !important;
}

.btn-openid {
  color: #22292f;
  background-color: #f7931e;
  border: none;
  color: #fff !important;
}

.btn-openid:hover {
  color: #22292f;
  background-color: #f8a340;
  border-color: #ffffff1a;
}

.btn-check:focus + .btn-openid, .btn-openid:focus {
  color: #22292f;
  background-color: #f8a340;
  border-color: #ffffff1a;
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-check:checked + .btn-openid, .btn-check:active + .btn-openid, .btn-openid:active, .btn-openid.active, .show > .btn-openid.dropdown-toggle {
  color: #22292f;
  background-color: #f9a94b;
  border-color: #ffffff1a;
}

.btn-check:checked + .btn-openid:focus, .btn-check:active + .btn-openid:focus, .btn-openid:active:focus, .btn-openid.active:focus, .show > .btn-openid.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-openid:disabled, .btn-openid.disabled {
  color: #22292f;
  background-color: #f7931e;
  border-color: #0000;
}

.btn-openid:focus {
  box-shadow: none !important;
}

.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
  border: none;
  color: #fff !important;
}

.btn-pinterest:hover {
  color: #fff;
  background-color: #ad1b21;
  border-color: #0003;
}

.btn-check:focus + .btn-pinterest, .btn-pinterest:focus {
  color: #fff;
  background-color: #ad1b21;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-pinterest, .btn-check:active + .btn-pinterest, .btn-pinterest:active, .btn-pinterest.active, .show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #a21a1f;
  border-color: #00000040;
}

.btn-check:checked + .btn-pinterest:focus, .btn-check:active + .btn-pinterest:focus, .btn-pinterest:active:focus, .btn-pinterest.active:focus, .show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-pinterest:disabled, .btn-pinterest.disabled {
  color: #fff;
  background-color: #cb2027;
  border-color: #0000;
}

.btn-pinterest:focus {
  box-shadow: none !important;
}

.btn-reddit {
  color: #22292f;
  background-color: #eff7ff;
  border: none;
  color: #fff !important;
}

.btn-reddit:hover {
  color: #22292f;
  background-color: #f1f8ff;
  border-color: #ffffff1a;
}

.btn-check:focus + .btn-reddit, .btn-reddit:focus {
  color: #22292f;
  background-color: #f1f8ff;
  border-color: #ffffff1a;
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-check:checked + .btn-reddit, .btn-check:active + .btn-reddit, .btn-reddit:active, .btn-reddit.active, .show > .btn-reddit.dropdown-toggle {
  color: #22292f;
  background-color: #f2f9ff;
  border-color: #ffffff1a;
}

.btn-check:checked + .btn-reddit:focus, .btn-check:active + .btn-reddit:focus, .btn-reddit:active:focus, .btn-reddit.active:focus, .show > .btn-reddit.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-reddit:disabled, .btn-reddit.disabled {
  color: #22292f;
  background-color: #eff7ff;
  border-color: #0000;
}

.btn-reddit:focus {
  box-shadow: none !important;
}

.btn-soundcloud {
  color: #fff;
  background-color: #f50;
  border: none;
  color: #fff !important;
}

.btn-soundcloud:hover {
  color: #fff;
  background-color: #d94800;
  border-color: #0003;
}

.btn-check:focus + .btn-soundcloud, .btn-soundcloud:focus {
  color: #fff;
  background-color: #d94800;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-soundcloud, .btn-check:active + .btn-soundcloud, .btn-soundcloud:active, .btn-soundcloud.active, .show > .btn-soundcloud.dropdown-toggle {
  color: #fff;
  background-color: #c40;
  border-color: #00000040;
}

.btn-check:checked + .btn-soundcloud:focus, .btn-check:active + .btn-soundcloud:focus, .btn-soundcloud:active:focus, .btn-soundcloud.active:focus, .show > .btn-soundcloud.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-soundcloud:disabled, .btn-soundcloud.disabled {
  color: #fff;
  background-color: #f50;
  border-color: #0000;
}

.btn-soundcloud:focus {
  box-shadow: none !important;
}

.btn-tumblr {
  color: #fff;
  background-color: #2c4762;
  border: none;
  color: #fff !important;
}

.btn-tumblr:hover {
  color: #fff;
  background-color: #253c53;
  border-color: #0003;
}

.btn-check:focus + .btn-tumblr, .btn-tumblr:focus {
  color: #fff;
  background-color: #253c53;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-tumblr, .btn-check:active + .btn-tumblr, .btn-tumblr:active, .btn-tumblr.active, .show > .btn-tumblr.dropdown-toggle {
  color: #fff;
  background-color: #23394e;
  border-color: #00000040;
}

.btn-check:checked + .btn-tumblr:focus, .btn-check:active + .btn-tumblr:focus, .btn-tumblr:active:focus, .btn-tumblr.active:focus, .show > .btn-tumblr.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-tumblr:disabled, .btn-tumblr.disabled {
  color: #fff;
  background-color: #2c4762;
  border-color: #0000;
}

.btn-tumblr:focus {
  box-shadow: none !important;
}

.btn-twitter {
  color: #22292f;
  background-color: #55acee;
  border: none;
  color: #fff !important;
}

.btn-twitter:hover {
  color: #22292f;
  background-color: #6fb8f1;
  border-color: #ffffff1a;
}

.btn-check:focus + .btn-twitter, .btn-twitter:focus {
  color: #22292f;
  background-color: #6fb8f1;
  border-color: #ffffff1a;
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-check:checked + .btn-twitter, .btn-check:active + .btn-twitter, .btn-twitter:active, .btn-twitter.active, .show > .btn-twitter.dropdown-toggle {
  color: #22292f;
  background-color: #77bdf1;
  border-color: #ffffff1a;
}

.btn-check:checked + .btn-twitter:focus, .btn-check:active + .btn-twitter:focus, .btn-twitter:active:focus, .btn-twitter.active:focus, .show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-twitter:disabled, .btn-twitter.disabled {
  color: #22292f;
  background-color: #55acee;
  border-color: #0000;
}

.btn-twitter:focus {
  box-shadow: none !important;
}

.btn-vimeo {
  color: #22292f;
  background-color: #1ab7ea;
  border: none;
  color: #fff !important;
}

.btn-vimeo:hover {
  color: #22292f;
  background-color: #3cc2ed;
  border-color: #ffffff1a;
}

.btn-check:focus + .btn-vimeo, .btn-vimeo:focus {
  color: #22292f;
  background-color: #3cc2ed;
  border-color: #ffffff1a;
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-check:checked + .btn-vimeo, .btn-check:active + .btn-vimeo, .btn-vimeo:active, .btn-vimeo.active, .show > .btn-vimeo.dropdown-toggle {
  color: #22292f;
  background-color: #48c5ee;
  border-color: #ffffff1a;
}

.btn-check:checked + .btn-vimeo:focus, .btn-check:active + .btn-vimeo:focus, .btn-vimeo:active:focus, .btn-vimeo.active:focus, .show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #22292f80;
}

.btn-vimeo:disabled, .btn-vimeo.disabled {
  color: #22292f;
  background-color: #1ab7ea;
  border-color: #0000;
}

.btn-vimeo:focus {
  box-shadow: none !important;
}

.btn-vk {
  color: #fff;
  background-color: #587ea3;
  border: none;
  color: #fff !important;
}

.btn-vk:hover {
  color: #fff;
  background-color: #4b6b8b;
  border-color: #0003;
}

.btn-check:focus + .btn-vk, .btn-vk:focus {
  color: #fff;
  background-color: #4b6b8b;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-vk, .btn-check:active + .btn-vk, .btn-vk:active, .btn-vk.active, .show > .btn-vk.dropdown-toggle {
  color: #fff;
  background-color: #466582;
  border-color: #00000040;
}

.btn-check:checked + .btn-vk:focus, .btn-check:active + .btn-vk:focus, .btn-vk:active:focus, .btn-vk.active:focus, .show > .btn-vk.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-vk:disabled, .btn-vk.disabled {
  color: #fff;
  background-color: #587ea3;
  border-color: #0000;
}

.btn-vk:focus {
  box-shadow: none !important;
}

.btn-yahoo {
  color: #fff;
  background-color: #720e9e;
  border: none;
  color: #fff !important;
}

.btn-yahoo:hover {
  color: #fff;
  background-color: #610c86;
  border-color: #0003;
}

.btn-check:focus + .btn-yahoo, .btn-yahoo:focus {
  color: #fff;
  background-color: #610c86;
  border-color: #0003;
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-check:checked + .btn-yahoo, .btn-check:active + .btn-yahoo, .btn-yahoo:active, .btn-yahoo.active, .show > .btn-yahoo.dropdown-toggle {
  color: #fff;
  background-color: #5b0b7e;
  border-color: #00000040;
}

.btn-check:checked + .btn-yahoo:focus, .btn-check:active + .btn-yahoo:focus, .btn-yahoo:active:focus, .btn-yahoo.active:focus, .show > .btn-yahoo.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #ffffff80;
}

.btn-yahoo:disabled, .btn-yahoo.disabled {
  color: #fff;
  background-color: #720e9e;
  border-color: #0000;
}

.btn-yahoo:focus {
  box-shadow: none !important;
}

.btn-outline-adn {
  color: #d87a68;
  border: 1px solid #d87a68 !important;
}

.btn-outline-bitbucket {
  color: #205081;
  border: 1px solid #205081 !important;
}

.btn-outline-dropbox {
  color: #1087dd;
  border: 1px solid #1087dd !important;
}

.btn-outline-facebook {
  color: #3b5998;
  border: 1px solid #3b5998 !important;
}

.btn-outline-flickr {
  color: #ff0084;
  border: 1px solid #ff0084 !important;
}

.btn-outline-foursquare {
  color: #f94877;
  border: 1px solid #f94877 !important;
}

.btn-outline-github {
  color: #444;
  border: 1px solid #444 !important;
}

.btn-outline-google {
  color: #dd4b39;
  border: 1px solid #dd4b39 !important;
}

.btn-outline-instagram {
  color: #3f729b;
  border: 1px solid #3f729b !important;
}

.btn-outline-linkedin {
  color: #007bb6;
  border: 1px solid #007bb6 !important;
}

.btn-outline-microsoft {
  color: #2672ec;
  border: 1px solid #2672ec !important;
}

.btn-outline-odnoklassniki {
  color: #f4731c;
  border: 1px solid #f4731c !important;
}

.btn-outline-openid {
  color: #f7931e;
  border: 1px solid #f7931e !important;
}

.btn-outline-pinterest {
  color: #cb2027;
  border: 1px solid #cb2027 !important;
}

.btn-outline-reddit {
  color: #ff4500;
  border: 1px solid #ff4500 !important;
}

.btn-outline-soundcloud {
  color: #f50;
  border: 1px solid #f50 !important;
}

.btn-outline-tumblr {
  color: #2c4762;
  border: 1px solid #2c4762 !important;
}

.btn-outline-twitter {
  color: #55acee;
  border: 1px solid #55acee !important;
}

.btn-outline-vimeo {
  color: #1ab7ea;
  border: 1px solid #1ab7ea !important;
}

.btn-outline-vk {
  color: #587ea3;
  border: 1px solid #587ea3 !important;
}

.btn-outline-yahoo {
  color: #720e9e;
  border: 1px solid #720e9e !important;
}

.btn-outline-adn:hover {
  color: #ad6253;
  border: 1px solid #ad6253 !important;
}

.btn-outline-bitbucket:hover {
  color: #1a4067;
  border: 1px solid #1a4067 !important;
}

.btn-outline-dropbox:hover {
  color: #0d6cb1;
  border: 1px solid #0d6cb1 !important;
}

.btn-outline-facebook:hover {
  color: #2f477a;
  border: 1px solid #2f477a !important;
}

.btn-outline-flickr:hover {
  color: #cc006a;
  border: 1px solid #cc006a !important;
}

.btn-outline-foursquare:hover {
  color: #c73a5f;
  border: 1px solid #c73a5f !important;
}

.btn-outline-github:hover {
  color: #363636;
  border: 1px solid #363636 !important;
}

.btn-outline-google:hover {
  color: #b13c2e;
  border: 1px solid #b13c2e !important;
}

.btn-outline-instagram:hover {
  color: #325b7c;
  border: 1px solid #325b7c !important;
}

.btn-outline-linkedin:hover {
  color: #006292;
  border: 1px solid #006292 !important;
}

.btn-outline-microsoft:hover {
  color: #1e5bbd;
  border: 1px solid #1e5bbd !important;
}

.btn-outline-odnoklassniki:hover {
  color: #c35c16;
  border: 1px solid #c35c16 !important;
}

.btn-outline-openid:hover {
  color: #c67618;
  border: 1px solid #c67618 !important;
}

.btn-outline-pinterest:hover {
  color: #a21a1f;
  border: 1px solid #a21a1f !important;
}

.btn-outline-reddit:hover {
  color: #cc3700;
  border: 1px solid #cc3700 !important;
}

.btn-outline-soundcloud:hover {
  color: #c40;
  border: 1px solid #c40 !important;
}

.btn-outline-tumblr:hover {
  color: #23394e;
  border: 1px solid #23394e !important;
}

.btn-outline-twitter:hover {
  color: #448abe;
  border: 1px solid #448abe !important;
}

.btn-outline-vimeo:hover {
  color: #1592bb;
  border: 1px solid #1592bb !important;
}

.btn-outline-vk:hover {
  color: #466582;
  border: 1px solid #466582 !important;
}

.btn-outline-yahoo:hover {
  color: #5b0b7e;
  border: 1px solid #5b0b7e !important;
}

.bg-adn {
  background-color: #d87a68;
}

.bg-bitbucket {
  background-color: #205081;
}

.bg-dropbox {
  background-color: #1087dd;
}

.bg-facebook {
  background-color: #3b5998;
}

.bg-flickr {
  background-color: #ff0084;
}

.bg-foursquare {
  background-color: #f94877;
}

.bg-github {
  background-color: #444;
}

.bg-google {
  background-color: #dd4b39;
}

.bg-instagram {
  background-color: #3f729b;
}

.bg-linkedin {
  background-color: #007bb6;
}

.bg-microsoft {
  background-color: #2672ec;
}

.bg-odnoklassniki {
  background-color: #f4731c;
}

.bg-openid {
  background-color: #f7931e;
}

.bg-pinterest {
  background-color: #cb2027;
}

.bg-reddit {
  background-color: #ff4500;
}

.bg-soundcloud {
  background-color: #f50;
}

.bg-tumblr {
  background-color: #2c4762;
}

.bg-twitter {
  background-color: #55acee;
}

.bg-vimeo {
  background-color: #1ab7ea;
}

.bg-vk {
  background-color: #587ea3;
}

.bg-yahoo {
  background-color: #720e9e;
}

.demo-inline-spacing {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.demo-inline-spacing > * {
  margin-top: 1.5rem;
  margin-right: 1.5rem;
}

.demo-vertical-spacing > * {
  margin: 0 0 1.5rem;
}

.demo-vertical-spacing > :last-child, .demo-spacing-0 > :last-child {
  margin: 0;
}

.icon-wrapper .feather {
  width: 24px;
  height: 24px;
}

.scroll-example {
  border: 2px solid #dfdfdf;
  padding: .5rem;
  position: relative;
  overflow: auto;
}

.scroll-example .horz-scroll-content {
  width: 1200px;
}

.browser {
  box-sizing: border-box;
  background: #e0e0e0;
  border: 4px solid #e0e0e0;
  width: 100%;
  height: 12rem;
  margin: 0 0 10px;
  padding-top: 20px;
}

.browser iframe {
  background: #fff;
  border: 0;
  width: 100%;
  height: 100%;
}

.loader-wrapper {
  height: 8em;
}

.maintenance-icon {
  font-size: 4rem;
}

.animationIcon {
  bottom: 10px;
  right: 30px;
}

#component-breadcrumbs .breadcrumb:not(last-child) {
  margin-bottom: .5rem;
}

#breadcrumb-alignment .breadcrumb-wrapper {
  border-radius: .357rem;
  padding: 1px;
  box-shadow: 1px 1px 10px #22292f1a;
}

#breadcrumb-alignment .breadcrumb-wrapper .breadcrumb {
  margin-top: .8rem;
  margin-bottom: .8rem;
}

.dark-layout #breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow: 1px 1px 10px #0000003d;
}

.buy-now {
  z-index: 1031;
  position: fixed;
  bottom: 5%;
  right: 79px;
}

.buy-now .btn {
  box-shadow: 0 1px 20px 1px #ea5455 !important;
}

.buy-now .btn:hover {
  box-shadow: none !important;
}

.customizer {
  z-index: 1051;
  height: calc(var(--vh, 1vh) * 100);
  backface-visibility: hidden;
  background-color: #fff;
  border-left: 1px solid #22292f0d;
  width: 400px;
  height: 100vh;
  padding: 0;
  transition: right .4s cubic-bezier(.05, .74, .2, .99);
  position: fixed;
  top: 0;
  bottom: 0;
  right: -400px;
}

.customizer.open {
  right: 0;
  box-shadow: 0 15px 30px #22292f1c, 0 5px 15px #22292f14;
}

.customizer .customizer-content {
  height: 100%;
  position: relative;
}

.customizer .customizer-close {
  z-index: 10;
  color: #5e5873;
  width: auto;
  padding: 7px;
  position: absolute;
  top: .6rem;
  right: .6rem;
}

.customizer .customizer-close i, .customizer .customizer-close svg {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.customizer .customizer-toggle {
  text-align: center;
  cursor: pointer;
  background: #7367f0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 38px;
  height: 38px;
  line-height: 40px;
  display: block;
  position: absolute;
  top: 50%;
  left: -39px;
  box-shadow: -3px 0 8px #22292f1a;
  color: #fff !important;
}

.customizer .color-box {
  cursor: pointer;
  border-radius: .5rem;
  width: 24px;
  height: 24px;
  margin: .5rem;
  position: relative;
}

.customizer .color-box.selected:after {
  content: "";
  border: 1px solid #7367f0;
  border-radius: .5rem;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -3px;
  left: -3px;
}

.customizer .color-box.selected.border:after {
  top: -4px;
  left: -4px;
}

.divider {
  text-align: center;
  white-space: nowrap;
  margin: 1rem 0;
  display: block;
  overflow: hidden;
}

.divider .divider-text {
  padding: 0 1rem;
  font-size: .9375rem;
  display: inline-block;
  position: relative;
}

.divider .divider-text i, .divider .divider-text svg {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.divider .divider-text:before, .divider .divider-text:after {
  content: "";
  border-top: 1px solid #ebe9f1;
  width: 9999px;
  position: absolute;
  top: 50%;
}

.divider .divider-text:before {
  right: 100%;
}

.divider .divider-text:after {
  left: 100%;
}

.divider.divider-start .divider-text {
  float: left;
  padding-left: 0;
}

.divider.divider-start .divider-text:before {
  display: none;
}

.divider.divider-start-center .divider-text {
  left: -25%;
}

.divider.divider-end .divider-text {
  float: right;
  padding-right: 0;
}

.divider.divider-end .divider-text:after {
  display: none;
}

.divider.divider-end-center .divider-text {
  right: -25%;
}

.divider.divider-dotted .divider-text:before, .divider.divider-dotted .divider-text:after {
  border: 1px dotted #ebe9f1;
  border-top-width: 0;
}

.divider.divider-dashed .divider-text:before, .divider.divider-dashed .divider-text:after {
  border: 1px dashed #ebe9f1;
  border-top-width: 0;
}

.timeline {
  margin-bottom: 0;
  margin-left: 1rem;
  padding: 0;
  list-style: none;
}

.timeline .timeline-item {
  border-left: 1px solid #ebe9f1;
  padding-left: 2.5rem;
  position: relative;
}

.timeline .timeline-item:not(:last-child) {
  padding-bottom: 1.8rem;
}

.timeline .timeline-item .timeline-point {
  z-index: 2;
  text-align: center;
  background-color: #fff;
  border: 1px solid #7367f0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  position: absolute;
  top: 0;
  left: -.85rem;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator {
  background-color: #7367f0;
  border: 0;
  width: 12px;
  height: 12px;
  top: .07rem;
  left: -.412rem;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  content: "";
  background: #7367f01f;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: -.285rem;
  left: -.285rem;
}

.timeline .timeline-item .timeline-point i, .timeline .timeline-item .timeline-point svg {
  color: #7367f0;
  vertical-align: baseline;
  font-size: .85rem;
}

.timeline .timeline-item .timeline-event {
  width: 100%;
  min-height: 4rem;
  position: relative;
}

.timeline .timeline-item .timeline-event .timeline-event-time {
  color: #b9b9c3;
  font-size: .85rem;
}

.timeline .timeline-item:last-of-type {
  border-left-color: #0000 !important;
}

.timeline .timeline-item:last-of-type:after {
  content: "";
  background: linear-gradient(#ebe9f1, #0000);
  width: 1px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: -1px;
}

.custom-options-checkable .custom-option-item {
  cursor: pointer;
  color: #82868b;
  background-color: #82868b0f;
  border: 1px solid #ebe9f1;
  border-radius: .42rem;
  width: 100%;
}

.custom-options-checkable .custom-option-item .custom-option-item-title {
  color: #82868b;
}

.custom-option-item-check {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.custom-option-item-check:checked + .custom-option-item {
  color: #7367f0;
  background-color: #7367f01f;
  border-color: #7367f0;
}

.custom-option-item-check:checked + .custom-option-item .custom-option-item-title {
  color: #7367f0;
}

@media screen and (width >= 0) {
  head {
    font-family: "xs 0px";
  }

  body:after {
    content: "xs - min-width: 0px";
  }
}

@media screen and (width >= 544px) {
  head {
    font-family: "sm 544px";
  }

  body:after {
    content: "sm - min-width: 544px";
  }
}

@media screen and (width >= 768px) {
  head {
    font-family: "md 768px";
  }

  body:after {
    content: "md - min-width: 768px";
  }
}

@media screen and (width >= 992px) {
  head {
    font-family: "lg 992px";
  }

  body:after {
    content: "lg - min-width: 992px";
  }
}

@media screen and (width >= 1200px) {
  head {
    font-family: "xl 1200px";
  }

  body:after {
    content: "xl - min-width: 1200px";
  }
}

head {
  clear: both;
}

head title {
  font-family: "xs 0px, sm 544px, md 768px, lg 992px, xl 1200px";
}

body:after, [data-usn-if] {
  display: none;
}

.select2-container {
  vertical-align: middle;
  box-sizing: border-box;
  margin: 0;
  display: inline-block;
  position: relative;
  width: 100% !important;
}

.select2-container--classic:focus, .select2-container--default:focus {
  outline: none;
}

.select2-container--classic .select2-selection__choice, .select2-container--default .select2-selection__choice {
  font-size: .9rem;
  margin-top: 6px !important;
}

.select2-container--classic .select2-selection--single, .select2-container--default .select2-selection--single {
  border: 1px solid #d8d6de;
  min-height: 2.714rem;
  padding: 5px;
}

.select2-container--classic .select2-selection--single:focus, .select2-container--default .select2-selection--single:focus {
  outline: 0;
  border-color: #7367f0 !important;
  box-shadow: 0 3px 10px #22292f1a !important;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered i, .select2-container--classic .select2-selection--single .select2-selection__rendered svg, .select2-container--default .select2-selection--single .select2-selection__rendered i, .select2-container--default .select2-selection--single .select2-selection__rendered svg {
  width: 1.15rem;
  height: 1.15rem;
  margin-right: .5rem;
  font-size: 1.15rem;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b, .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px 14px, 18px 14px;
  border-style: none;
  height: 1rem;
  margin-top: 0;
  margin-left: 0;
  padding-right: 1.5rem;
  left: -8px;
}

.select2-container--classic.select2-container--open, .select2-container--default.select2-container--open {
  box-shadow: 0 5px 25px #22292f1a;
}

.select2-container--classic.select2-container--open .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--single {
  outline: 0;
  border-color: #7367f0 !important;
}

.select2-container--classic.select2-container--focus, .select2-container--default.select2-container--focus {
  outline: 0;
}

.select2-container--classic.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--multiple {
  outline: 0;
  border-color: #7367f0 !important;
}

.select2-container--classic .select2-dropdown, .select2-container--default .select2-dropdown {
  z-index: 1060;
  border-color: #d8d6de;
}

.select2-container--classic .select2-search--dropdown, .select2-container--default .select2-search--dropdown {
  padding: .5rem;
}

.select2-container--classic .select2-search--dropdown .select2-search__field, .select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #d8d6de;
  border-radius: .357rem;
  padding: .571rem 1rem;
  outline: none !important;
}

.select2-container--classic .select2-selection--multiple, .select2-container--default .select2-selection--multiple {
  border: 1px solid #d8d6de;
  min-height: 38px !important;
}

.select2-container--classic .select2-selection--multiple:focus, .select2-container--default .select2-selection--multiple:focus {
  outline: 0;
  border-color: #7367f0 !important;
  box-shadow: 0 3px 10px #22292f1a !important;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice, .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  padding: 2px 5px;
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered, .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 6px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered li .select2-search__field, .select2-container--default .select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top: 7px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  float: right;
  margin-left: .7rem;
  margin-right: .5rem;
  font-size: 0;
  line-height: 1rem;
  display: inline-block;
  position: relative;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:before, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  background-size: .85rem;
  width: .85rem;
  height: .85rem;
  position: absolute;
  top: 22%;
  left: -4px;
}

.select2-container--classic .select2-selection--multiple i, .select2-container--classic .select2-selection--multiple svg, .select2-container--default .select2-selection--multiple i, .select2-container--default .select2-selection--multiple svg {
  width: 1.15rem;
  height: 1.15rem;
  margin-right: .5rem;
  padding-left: 1px;
  font-size: 1.15rem;
  position: relative;
  top: 1px;
}

.select2-container--classic .select2-results__group, .select2-container--default .select2-results__group {
  padding: 6px 9px;
  font-weight: 600;
}

.select2-container--classic .select2-results__option, .select2-container--default .select2-results__option {
  padding: .428rem 1rem;
}

.select2-container--classic .select2-results__option[role="group"], .select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-selected="true"], .select2-container--default .select2-results__option[aria-selected="true"] {
  color: #fff !important;
  background-color: #7367f0 !important;
}

.select2-container--classic .select2-results__option i, .select2-container--classic .select2-results__option svg, .select2-container--default .select2-results__option i, .select2-container--default .select2-results__option svg {
  width: 1.15rem;
  height: 1.15rem;
  margin-right: .5rem;
  font-size: 1.15rem;
}

.select2-container--classic .select2-results__option--highlighted, .select2-container--default .select2-results__option--highlighted {
  color: #7367f0 !important;
  background-color: #7367f01f !important;
}

.select2-container--classic .select2-result-repository__avatar img, .select2-container--default .select2-result-repository__avatar img {
  width: 50px;
}

.select2-container--classic .select-lg, .select2-container--default .select-lg {
  padding: .3rem .7rem;
  font-size: 1.2rem;
  min-height: 2.714rem !important;
  margin-bottom: 0 !important;
}

.select2-container--classic .select-lg.select2-selection--single .select2-selection__rendered, .select2-container--default .select-lg.select2-selection--single .select2-selection__rendered {
  padding-top: -.1rem;
}

.select2-container--classic .select-lg.select2-selection--single .select2-selection__arrow, .select2-container--default .select-lg.select2-selection--single .select2-selection__arrow {
  top: 0 !important;
}

.select2-container--classic .select-lg.select2-selection--multiple, .select2-container--default .select-lg.select2-selection--multiple {
  padding: 0 .2rem;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered {
  padding-top: 0 !important;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li {
  font-size: 1.2rem;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top: 10px;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding: 5px;
}

.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before, .select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before {
  top: 46%;
}

.select2-container--classic .select-sm, .select2-container--default .select-sm {
  padding: 0 .2rem;
  font-size: .75rem;
  line-height: 1.45;
  min-height: 2.142rem !important;
  margin-bottom: 0 !important;
}

.select2-container--classic .select-sm.select2-selection--single .select2-selection__arrow, .select2-container--default .select-sm.select2-selection--single .select2-selection__arrow {
  top: -.3rem !important;
}

.select2-container--classic .select-sm.select2-selection--multiple, .select2-container--default .select-sm.select2-selection--multiple {
  line-height: 1.25;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered {
  padding: 3px;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li {
  margin-top: 2px;
  font-size: .75rem;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top: 4px;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 0 .2rem;
  margin-top: 4px !important;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before, .select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before {
  top: 5%;
}

.select2-container--classic .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field, .select2-container--default .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.dark-layout .select2-container .select2-selection, .dark-layout .select2-container .select2-search__field, .dark-layout .select2-container .select2-selection__placeholder {
  color: #b4b7bd;
  background: #283046;
  border-color: #3b4253;
}

.dark-layout .select2-container .select2-selection__rendered {
  color: #b4b7bd;
}

.dark-layout .select2-container .select2-dropdown {
  background-color: #283046;
  border-color: #3b4253;
}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice {
  border: none;
  color: #7367f0 !important;
  background: #7367f01f !important;
}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: #7367f0 !important;
}

.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice__remove:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
}

.dark-layout .select2-container .select2-results__option[aria-disabled="true"] {
  color: #676d7d;
}

.dark-layout .select2-container .select2-selection__arrow b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b4b7bd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.dark-layout .select2-container--disabled .select2-selection {
  opacity: .5;
}

table.dataTable {
  border-collapse: collapse !important;
  width: 100% !important;
}

table.dataTable.table-responsive:not(.datatables-ajax) {
  display: block;
}

table.dataTable td, table.dataTable th {
  vertical-align: middle;
  padding: .72rem 1.5rem;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
  padding-right: inherit;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before {
  color: #6e6b7b;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 14px;
  height: 14px;
  top: .5rem;
  right: .3rem;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after {
  color: #6e6b7b;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 14px;
  height: 14px;
  top: 1.3rem;
  right: .3rem;
}

div.dataTables_wrapper .dt-button-collection {
  background-color: #fff;
}

div.dataTables_wrapper .dt-button-collection > div[role="menu"] {
  text-align: left;
  min-width: 8rem;
  box-shadow: 0 5px 16px -8px;
}

div.dataTables_wrapper .dt-button-collection [class*="buttons-"]:focus {
  outline: 0;
}

table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  line-height: .8em;
}

@media screen and (width <= 1399.98px) {
  table.dataTable.table-responsive {
    display: block;
  }
}

.modal.dtr-bs-modal .modal-body {
  padding: 0;
}

.modal.dtr-bs-modal .table tr:last-child > td {
  border-bottom: 0;
}

div.dataTables_wrapper div.dataTables_filter label, div.dataTables_wrapper div.dataTables_length label {
  margin-top: 1rem;
  margin-bottom: .5rem;
}

div.dataTables_wrapper div.dataTables_filter select, div.dataTables_wrapper div.dataTables_length select {
  background-position: calc(100% - 3px) 11px, calc(100% - 20px) 13px, 100% 0;
  width: 5rem;
  margin: 0 .5rem;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: .75rem;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

div.dataTables_wrapper .dataTables_info {
  color: #b9b9c3;
  padding-top: 1.25rem !important;
}

tr.group {
  background-color: #fafafc;
}

@media only screen and (width <= 992px) {
  table.dataTable.table-responsive.datatables-ajax {
    display: block;
  }
}

@media only screen and (width <= 768px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center;
  }

  div.dataTables_wrapper div.dataTables_info {
    white-space: inherit;
  }

  div.dt-buttons {
    justify-content: center;
    margin-top: 1rem;
  }

  div.dt-buttons button {
    margin-bottom: .5rem;
  }
}

@media (width <= 575.98px) {
  .dtr-bs-modal .modal-body {
    padding-left: 0;
    padding-right: 0;
    overflow-x: scroll;
  }

  .dtr-bs-modal .table.dtr-details {
    margin: 0 auto 1rem;
  }

  .dtr-bs-modal .table.dtr-details td {
    padding-left: 0;
    padding-right: 0;
  }

  .dtr-bs-modal .table.dtr-details tr td:first-child {
    padding-left: .75rem;
  }

  div.dataTables_wrapper {
    overflow: scroll;
  }
}

.bootstrap-touchspin.input-group {
  align-items: center;
  width: 8.4rem;
}

.bootstrap-touchspin.input-group .form-control {
  text-align: center;
  background-color: #f8f8f8;
  border: 0;
  height: auto;
  padding: 5px;
  font-weight: 500;
  border-radius: .357rem !important;
}

.bootstrap-touchspin.input-group .form-control:focus {
  z-index: 1;
  box-shadow: none;
}

.bootstrap-touchspin.input-group > .input-group-btn:first-child {
  position: inherit;
  left: 12px !important;
}

.bootstrap-touchspin.input-group > .input-group-btn:last-child {
  position: inherit;
  right: 12px !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected {
  margin: 0 !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  border-radius: .357rem !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down i, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down svg, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up i, .bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up svg {
  width: .8rem;
  height: .8rem;
  font-size: .8rem;
  position: relative;
  top: -1px;
}

.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down, .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up {
  cursor: default;
  opacity: .5;
  background-color: #22292f80 !important;
}

.bootstrap-touchspin.input-group-lg {
  width: 9.375rem;
}

.bootstrap-touchspin.input-group-lg .touchspin.form-control {
  height: auto !important;
}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up {
  min-width: 24px;
  min-height: 24px;
}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down i, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down svg, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up i, .bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up svg {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  top: 0;
}

.bootstrap-touchspin.input-group-sm {
  width: 6.25rem;
}

.bootstrap-touchspin.input-group-sm .touchspin.form-control {
  height: auto !important;
}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  min-width: 16px;
  min-height: 16px;
}

.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down i, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down svg, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up i, .bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up svg {
  width: .6rem;
  height: .6rem;
  font-size: .6rem;
  top: -3px;
}

.bootstrap-touchspin.input-group input[type="number"] {
  -moz-appearance: textfield;
}

.dark-layout .bootstrap-touchspin.input-group .form-control {
  background-color: #161d31;
}

.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down, .dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up, .dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .disabled-max-min, .dark-layout .bootstrap-touchspin .bootstrap-touchspin-injected .disabled-max-min {
  opacity: 1;
  background-color: #b8c2cc !important;
}

/*# sourceMappingURL=index.cb0d751f.css.map */
